import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, Calendar, MapPin } from 'lucide-react';
import weddingvideo from './images/weddinginvitevideo.mp4'; // Replace with your actual video file name
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore"; 
import backgroundImage from './images/backgroundimage1.png';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const firebaseConfig = {
    apiKey: "AIzaSyAKbgcgKAktEezCplrW141T1btCr0RFSg8",
    authDomain: "wedding-website-2296b.firebaseapp.com",
    projectId: "wedding-website-2296b",
    storageBucket: "wedding-website-2296b.appspot.com",
    messagingSenderId: "1090073955156",
    appId: "1:1090073955156:web:8b7d783ec79006f46dd6c1"
  };


  const TypewriterText = ({ text }) => {
    const [displayText, setDisplayText] = useState([]);

    useEffect(() => {
        let i = 0;
        const typingEffect = setInterval(() => {
            if (i < text.length) {
                setDisplayText((prev) => [...prev, text.charAt(i)]);
                i++;
            } else {
                clearInterval(typingEffect);
            }
        }, 50); // Adjust speed here

        return () => clearInterval(typingEffect);
    }, [text]);

    return (
        <div className="flex justify-center items-center mt-2">
            <p 
                className="text-center text-2xl font-amiri leading-loose" 
                style={{ 
                    color: '#D4AF37', 
                    direction: 'rtl', 
                    textAlign: 'center', 
                    marginTop: '1rem',
                    fontFamily: "'Amiri', serif",
                    lineHeight: '3.5rem' // Adjust line height for space between lines
                }}
            >
                {displayText.join('')}
            </p>
        </div>
    );
};



  
const WeddingSite = () => {

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);// Initialize Firebase


  
  const [formData, setFormData] = useState({
    name: '',
    partySize: 1,
    partyNames: [''],
    attending: []
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [expandedEvent, setExpandedEvent] = useState(null);
  const sectionRefs = useRef([]);

  const convertTo12HourFormat = (time) => {
    const [start, end] = time.split(' - ');
    const convertTime = (t) => {
      const [hours, minutes] = t.split(':');
      const hour = parseInt(hours, 10);
      const ampm = hour >= 12 ? 'PM' : 'AM';
      const formattedHour = hour % 12 || 12;
      return `${formattedHour}:${minutes} ${ampm}`;
    };
    return `${convertTime(start)} - ${convertTime(end)} EST`;
  };

  const events = [
    {
      name: "Engagement Party",
      date: "2024-11-07",
      time: "20:00 - 23:00",
      address: "7501 Lupine Dr, Tampa, FL 33610",
      details: ["Introductions", "Ring Ceremony", "Dinner"],
      dressCode: "Men: Formal. Avoid Blue <br/> Ladies: Any Desi Dress. Avoid Red",
      image: "https://i.ibb.co/ZfZQ7hq/IMG-8183.jpg"
    },
    {
      name: "Nikkah Ceremony",
      date: "2024-11-08",
      time: "15:00 - 18:00",
      address: "7501 Lupine Dr, Tampa, FL 33610",
      details: ["Nikkah Ceremony", "Dinner", "Farewells"],
      dressCode: "Men: White/Beige Panjabi with Family Matching Vests. <br/> Ladies: Light Colored Desi Dress. Avoid White/Beige. Avoid Gharara.",
      image: "https://i.ibb.co/LRbHfzN/background-2.png"
    }
  ];

  const toggleEvent = (index) => {
    setIsDetailsExpanded(!isDetailsExpanded);
    if (expandedEvent === index) {
      setExpandedEvent(null);
    } else {
      setExpandedEvent(index);
    }
  };

  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);


  const scrollToNextSection = (index) => {
    if (index >= 0 && index < sectionRefs.current.length) {
      sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
      setCurrentIndex(index);
    }
  };

  const handleTouchStart = (e) => {
  if (!isDetailsExpanded && (currentIndex !== 2 || currentIndex !== 3)) {
    const touchStartY = e.touches[0].clientY;
    sectionRefs.current[currentIndex].dataset.touchStartY = touchStartY;
  }
};

const handleTouchEnd = (e) => {
  if (!isDetailsExpanded && (currentIndex !== 2 || currentIndex !== 3)) {
    const touchEndY = e.changedTouches[0].clientY;
    const touchStartY = parseFloat(sectionRefs.current[currentIndex].dataset.touchStartY);

    if (touchStartY - touchEndY > 50) {
      // Swipe up, go to next section
      scrollToSection(currentIndex + 1);
    } else if (touchEndY - touchStartY > 50) {
      // Swipe down, go to previous section
      if (currentIndex === 4) { // If we're on the RSVP section
        scrollToSection(3); // Go to Event 2 (Nikkah Ceremony)
      } else {
        scrollToSection(currentIndex - 1);
      }
    }
  }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePartySizeChange = (e) => {
    const size = parseInt(e.target.value);
    setFormData(prevData => ({
      ...prevData,
      partySize: size,
      partyNames: Array(size).fill('')
    }));
  };

  const handlePartyNameChange = (index, value) => {
    setFormData(prevData => {
      const newPartyNames = [...prevData.partyNames];
      newPartyNames[index] = value;
      return { ...prevData, partyNames: newPartyNames };
    });
  };

  const handleAttendingChange = (event) => {
    setFormData(prevData => {
      const newAttending = prevData.attending.includes(event)
        ? prevData.attending.filter(e => e !== event)
        : [...prevData.attending, event];
      return { ...prevData, attending: newAttending };
    });
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  

  const scrollToSection = (index) => {
    if (index >= 0 && index < sectionRefs.current.length) {
      sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
      setCurrentIndex(index);
    }
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const docRef = await addDoc(collection(db, "RSVPs"), {
      name: formData.name,
      partySize: formData.partySize,
      partyNames: formData.partyNames,
      attending: formData.attending,
      timestamp: new Date(),
    });
    console.log("Document written with ID: ", docRef.id);
    setIsSubmitted(true);
  } catch (error) {
    console.error("Error adding document: ", error);
    alert("There was an error saving your RSVP. Please try again.");
  }
};



const addToCalendar = (event) => {
  const { name, date, time, address } = event;
  const [startTime, endTime] = time.split(' - ');

  const createUTCDate = (dateStr, timeStr) => {
    const [year, month, day] = dateStr.split('-').map(Number);
    const [hours, minutes] = timeStr.split(':').map(Number);
    
    // Create date in EST (UTC-5)
    // Note: After DST ends, EST is UTC-5
    const estDate = new Date(Date.UTC(year, month - 1, day, hours + 5, minutes));
    
    // DST ends on November 3, 2024 for the US
    const dstEndDate2024 = new Date(Date.UTC(2024, 10, 3)); // Month is 0-indexed
    
    // If the date is before DST ends, adjust by subtracting an hour
    if (estDate < dstEndDate2024) {
      estDate.setUTCHours(estDate.getUTCHours() - 1);
    }
    
    return estDate;
  };

  // Create start and end dates
  const startDateTime = createUTCDate(date, startTime);
  const endDateTime = createUTCDate(date, endTime);

  // Format dates for Google Calendar URL
  const formatForCalendar = (date) => {
    return date.toISOString().replace(/-|:|\.\d\d\d/g, '');
  };

  const eventName = `Piyal & Tahsina's ${name}`;

  const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(eventName)}&dates=${formatForCalendar(startDateTime)}/${formatForCalendar(endDateTime)}&details=${encodeURIComponent(`${name} at ${address} (Eastern Time)`)}&location=${encodeURIComponent(address)}&timeZone=America/New_York`;

  window.open(calendarUrl, '_blank');
};


  const openMaps = (address) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`, '_blank');
  };

  return (
    <div className="bg-black text-white font-serif overflow-y-scroll snap-y snap-mandatory h-screen"
    onTouchStart={handleTouchStart}
    onTouchEnd={handleTouchEnd}
      >
  <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Amiri&display=swap" rel="stylesheet" />


  <section
  ref={(el) => (sectionRefs.current[0] = el)}
  className="h-screen flex flex-col justify-between items-center p-6 snap-start snap-always"
  style={{ 
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#f0f0f0', // fallback color
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }}
>

  <div className="flex flex-col justify-start items-center h-full">
    <div className="text-center mt-6 mb-4">
      <h1 className="text-6xl mb-2 font-['Dancing_Script'] font-bold" style={{ color: '#D4AF37' }}>Piyal & Tahsina</h1>
      <p className="text-4xl mt-2 font-['Dancing_Script']" style={{ color: '#D4AF37' }}>are getting married</p>
    </div>
    <div className="flex justify-center items-center mt-2">
    <TypewriterText 
        text="رَبِّ هَبْ لَنَا مِنْ أَزْوَاجِنَا وَذُرِّيَّاتِنَا قُرَّةَ أَعْيُنٍ وَاجْعَلْنَا لِلْمُتَّقِينَ إِمَامًا، وَاجْعَلْ بَيْنَنَا مَوَدَّةً وَرَحْمَةً، وَبَارِكْ لَنَا فِي زَوَاجِنَا وَرَزُقْنَا الذُّرِّيَّةَ الصَّالِحَةَ." 
      />
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
   
    
    <div className="text-center mt-6">
      <p className="text-3xl" style={{ color: '#D4AF37' }}>Nov 7, 2024 - Nov 8, 2024</p>
      <br />
      <ChevronDown 
        size={32} 
        className="mx-auto cursor-pointer animate-bounce" 
        style={{ color: '#D4AF37' }} 
        onClick={() => scrollToNextSection(1)} 
      />
    </div>
  </div>
</section>






      {/* Video Section */}
      <section
        ref={(el) => (sectionRefs.current[1] = el)}
        className="h-screen w-full relative overflow-hidden bg-black snap-start snap-always"
      >
          <video 
    className="absolute top-0 left-0 w-full h-full object-cover"
    autoPlay
    muted
    loop
    playsInline
  >
    <source src={weddingvideo} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</section>
{/* Event 1 */}
{/* Event 1 */}
<section
  className="min-h-screen flex flex-col items-center p-6 bg-cover bg-center snap-start snap-always"
  style={{ backgroundImage: `url(${backgroundImage})` }}
>
  <h2 className="text-5xl font-['Dancing_Script'] text-center w-full" style={{ color: '#D4AF37', marginTop: '2rem' }}>Engagement Party</h2>
  <p className="text-xl text-center w-full" style={{ color: '#D4AF37', marginTop: '0.25rem' }}>Join us in celebrating our engagement</p>
  <div className="w-full max-w-3xl mt-2">
    <div className="overflow-hidden rounded-lg shadow-lg">
      <img src={events[0].image} alt={events[0].name} className="w-full h-64 object-cover filter grayscale mt-2" />
      <div className="bg-white bg-opacity-70 p-4">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleEvent(0)}
        >
          <h3 className="text-2xl font-['Dancing_Script']" style={{ color: '#D4AF37' }}>{events[0].name}</h3>
{/* Event 1 Section */}
<ChevronDown className={`transform transition-transform ${expandedEvent === 0 ? 'rotate-180' : ''}`} style={{ color: '#D4AF37' }} />
       </div>
        <br></br>
        <div className="flex justify-between mb-4">
          {events[0].details.map((detail, i) => (
            <span key={i} className="text-sm" style={{ color: '#D4AF37' }}>{detail}</span>
          ))}
        </div>
        <div className={`transition-all duration-500 ease-in-out ${expandedEvent === 0 ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="mt-4">
            <p 
              className="mb-2" 
              style={{ color: '#D4AF37' }} 
              dangerouslySetInnerHTML={{ __html: `Dress Code:<br/>${events[0].dressCode}` }}
            />
            {/* <p className="mb-2" style={{ color: '#D4AF37' }}>
            <strong>Time:</strong> {convertTo12HourFormat(events[0].time)}
            </p>
            <p className="mb-4" style={{ color: '#D4AF37' }}>
              <strong>Location:</strong> {events[0].address}
            </p> */}
            <div className="flex justify-between">
              <button onClick={(e) => { e.stopPropagation(); addToCalendar(events[0]); }} className="flex items-center text-sm bg-blue-900 text-white px-3 py-1 rounded hover:bg-blue-800 transition-colors" style={{ backgroundColor: '#D4AF37', color: '#000' }}>
                <Calendar size={16} className="mr-1" /> Add to Calendar
              </button>
              <button onClick={(e) => { e.stopPropagation(); openMaps(events[0].address); }} className="flex items-center text-sm bg-blue-900 text-white px-3 py-1 rounded hover:bg-blue-800 transition-colors" style={{ backgroundColor: '#D4AF37', color: '#000' }}>
                <MapPin size={16} className="mr-1" /> View on Map
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br></br>
  <ChevronDown 
  size={32} 
  className="mx-auto cursor-pointer animate-bounce" 
  style={{ color: '#D4AF37' }} 
  onClick={() => scrollToNextSection(3)}
/> </section>

{/* Event 2 */}
<section
  ref={(el) => (sectionRefs.current[3] = el)}
  className="min-h-screen flex flex-col items-center p-6 bg-[url('https://i.ibb.co/QHwKs4L/Blue-Gold-Watercolor-Floral-Muslim-Wedding-Invitation.png')] bg-cover bg-center snap-start snap-always"
>
  <h2 className="text-5xl font-['Dancing_Script'] text-center w-full" style={{ color: '#D4AF37', marginTop: '2rem' }}>Nikkah Ceremony</h2>
  <p className="text-xl text-center w-full" style={{ color: '#D4AF37', marginTop: '0.25rem' }}>Join us in celebrating our Nikkah</p>
  <div className="w-full max-w-3xl mt-2">
    <div className="overflow-hidden rounded-lg shadow-lg">
      <img src={events[1].image} alt={events[1].name} className="w-full h-64 object-cover filter grayscale mt-2" />
      <div className="bg-white bg-opacity-70 p-4">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleEvent(1)}
        >
          <h3 className="text-2xl font-['Dancing_Script']" style={{ color: '#D4AF37' }}>{events[1].name}</h3>
          <ChevronDown className={`transform transition-transform ${expandedEvent === 1 ? 'rotate-180' : ''}`} style={{ color: '#D4AF37' }} />
        </div>
        <br></br>
        <div className="flex justify-between mb-4">
          {events[1].details.map((detail, i) => (
            <span key={i} className="text-sm" style={{ color: '#D4AF37' }}>{detail}</span>
          ))}
        </div>
        <div className={`transition-all duration-500 ease-in-out ${expandedEvent === 1 ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="mt-4">
          <p 
  className="mb-2" 
  style={{ 
    color: '#D4AF37',
    fontSize: '0.875rem',
  }} 
  dangerouslySetInnerHTML={{ __html: `Dress Code:<br/>${events[1].dressCode}` }}
/>
            {/* <p className="mb-2" style={{ color: '#D4AF37' }}>
            <strong>Time:</strong> {convertTo12HourFormat(events[1].time)}
            </p>
            <p className="mb-4" style={{ color: '#D4AF37' }}>
              <strong>Location:</strong> {events[1].address}
            </p> */}
            <div className="flex justify-between">
              <button onClick={(e) => { e.stopPropagation(); addToCalendar(events[1]); }} className="flex items-center text-sm bg-blue-900 text-white px-3 py-1 rounded hover:bg-blue-800 transition-colors" style={{ backgroundColor: '#D4AF37', color: '#000' }}>
                <Calendar size={16} className="mr-1" /> Add to Calendar
              </button>
              <button onClick={(e) => { e.stopPropagation(); openMaps(events[1].address); }} className="flex items-center text-sm bg-blue-900 text-white px-3 py-1 rounded hover:bg-blue-800 transition-colors" style={{ backgroundColor: '#D4AF37', color: '#000' }}>
                <MapPin size={16} className="mr-1" /> View on Map
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br></br>
  <ChevronDown 
    size={32} 
    className="mx-auto cursor-pointer animate-bounce" 
    style={{ color: '#D4AF37' }} 
    onClick={() => scrollToNextSection(4)}
  />
</section>



    {/* RSVP */}
<section
  ref={(el) => (sectionRefs.current[4] = el)}
  className="min-h-screen p-6 bg-cover bg-center flex flex-col snap-start snap-always"
  style={{ backgroundImage: `url(${backgroundImage})` }}
>
  <h2 className="text-4xl mb-4 font-['Dancing_Script'] text-center" style={{ color: '#D4AF37' }}>RSVP</h2>
  <p className="mb-6 text-lg font-['Dancing_Script'] text-center" style={{ color: '#D4AF37' }}>We look forward to celebrating with you!</p>
  
  <div className="flex-grow overflow-y-auto relative" style={{ maxHeight: 'calc(100vh - 250px)' }}>
    {!isSubmitted ? (
      <form onSubmit={handleSubmit} className="space-y-4 pb-6">
        <div>
          <label htmlFor="name" className="block mb-1" style={{ color: '#D4AF37' }}>Your Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full bg-transparent border border-blue-900 p-2 rounded"
            style={{ color: '#D4AF37', borderColor: '#D4AF37' }}
            required
          />
        </div>
        <div>
          <label htmlFor="partySize" className="block mb-1" style={{ color: '#D4AF37' }}>Number in Party</label>
          <select
            id="partySize"
            name="partySize"
            value={formData.partySize}
            onChange={handlePartySizeChange}
            className="w-full bg-transparent border border-blue-900 p-2 rounded"
            style={{ color: '#D4AF37', borderColor: '#D4AF37' }}
            required
          >
            {[1, 2, 3, 4, 5].map(num => (
              <option key={num} value={num} style={{ color: '#000' }}>{num}</option>
            ))}
          </select>
        </div>
        {formData.partySize > 1 && (
          <div className="space-y-4">
            {[...Array(formData.partySize - 1)].map((_, index) => (
              <div key={index}>
                <label htmlFor={`partyName${index}`} className="block mb-1" style={{ color: '#D4AF37' }}>
                  Guest {index + 1} Name <span className="text-sm">(optional)</span>
                </label>
                <input
                  type="text"
                  id={`partyName${index}`}
                  value={formData.partyNames[index]}
                  onChange={(e) => handlePartyNameChange(index, e.target.value)}
                  className="w-full bg-transparent border border-blue-900 p-2 rounded"
                  style={{ color: '#D4AF37', borderColor: '#D4AF37' }}
                />
              </div>
            ))}
          </div>
        )}
        <div>
          <p className="mb-2" style={{ color: '#D4AF37' }}>Attending</p>
          {events.map((event, index) => (
            <label key={index} className="flex items-center mb-2" style={{ color: '#D4AF37' }}>
              <input
                type="checkbox"
                checked={formData.attending.includes(event.name)}
                onChange={() => handleAttendingChange(event.name)}
                className="mr-2"
              />
              <span>{event.name}</span>
            </label>
          ))}
        </div>
        <button type="submit" className="w-full bg-blue-900 py-2 rounded" style={{ color: '#D4AF37' }}>
          I'm There!
        </button>
      </form>
    ) : (
      <div className="text-center space-y-4">
        <h3 className="text-3xl font-['Dancing_Script']" style={{ color: '#D4AF37' }}>You're There!</h3>
        <p className="text-xl" style={{ color: '#D4AF37' }}>Thank you for your RSVP.</p>
        <div className="flex flex-col items-center space-y-4 mt-4">
          {events.map((event, index) => (
            formData.attending.includes(event.name) && (
              <div key={index} className="space-y-2 w-full">
                <button 
                  onClick={() => addToCalendar(event)} 
                  className="w-full flex items-center justify-center bg-blue-900 text-white px-4 py-2 rounded hover:bg-blue-800 transition-colors"
                  style={{ backgroundColor: '#D4AF37', color: '#000' }}
                >
                  <Calendar size={16} className="mr-2" /> Add {event.name} to Calendar
                </button>
                <button 
                  onClick={() => openMaps(event.address)} 
                  className="w-full flex items-center justify-center bg-blue-900 text-white px-4 py-2 rounded hover:bg-blue-800 transition-colors"
                  style={{ backgroundColor: '#D4AF37', color: '#000' }}
                >
                  <MapPin size={16} className="mr-2" /> View {event.name} on Map
                </button>
              </div>
            )
          ))}
        </div>
      </div>
    )}
    
    {/* ChevronDown for consistent navigation */}
    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
      <ChevronDown 
        size={0} 
        className="mx-auto cursor-pointer animate-bounce" 
        style={{ color: '#000000' }} 
        onClick={() => scrollToNextSection(5)}  // Adjust this index if needed
      />
    </div>
  </div>
</section>

      {/* Footer */}
      <footer className="p-6 text-center">
        <p>&copy; 2024 Piyal & Tahsina's Wedding Ceremonies</p>
      </footer>
    </div>
  );
};

export default WeddingSite;