import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc, deleteDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAKbgcgKAktEezCplrW141T1btCr0RFSg8",
    authDomain: "wedding-website-2296b.firebaseapp.com",
    projectId: "wedding-website-2296b",
    storageBucket: "wedding-website-2296b.appspot.com",
    messagingSenderId: "1090073955156",
    appId: "1:1090073955156:web:8b7d783ec79006f46dd6c1"
  };

  const RSVPList = () => {
    const [rsvps, setRSVPs] = useState([]);
    const [pin, setPin] = useState('');
    const [enteredPin, setEnteredPin] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
  
    useEffect(() => {
      const fetchPin = async () => {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const pinsCollection = collection(db, "Pins");
        const pinsSnapshot = await getDocs(pinsCollection);
  
        if (!pinsSnapshot.empty) {
          const pinDoc = pinsSnapshot.docs[0];
          const pinData = pinDoc.data();
          setPin(pinData.pin);
        } else {
          console.error("No PIN document found in the Pins collection.");
        }
      };
  
      fetchPin();
    }, []);
  
    const fetchRSVPs = async () => {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      const rsvpsCollection = collection(db, "RSVPs");
      const rsvpsSnapshot = await getDocs(rsvpsCollection);
      const rsvpsData = rsvpsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setRSVPs(rsvpsData);
    };
  
    const handlePinSubmit = (e) => {
      e.preventDefault();
      const enteredPinNumber = parseInt(enteredPin, 10);
      if (enteredPinNumber === pin) {
        setAuthenticated(true);
        fetchRSVPs();
      } else {
        alert('Incorrect PIN. Please try again.');
      }
    };
  
    const handleDelete = async (rsvpId) => {
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      await deleteDoc(doc(db, "RSVPs", rsvpId));
      fetchRSVPs();
    };
  
    return (
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          {!authenticated ? (
            <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          ) : (
            <div className="absolute inset-0 bg-gradient-to-r from-green-300 to-green-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          )}
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            {!authenticated ? (
              <div className="max-w-md mx-auto">
                <div>
                  <h1 className="text-2xl font-semibold">Enter PIN</h1>
                </div>
                <div className="divide-y divide-gray-200">
                  <form onSubmit={handlePinSubmit} className="py-8 text-base leading-6 space-y-4 sm:text-lg sm:leading-7">
                    <div className="relative">
                      <input
                        autoComplete="off"
                        id="pin"
                        name="pin"
                        type="password"
                        className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                        placeholder="PIN"
                        value={enteredPin}
                        onChange={(e) => setEnteredPin(e.target.value)}
                      />
                      <label htmlFor="pin" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">PIN</label>
                    </div>
                    <div className="relative">
                      <button type="submit" className="bg-blue-500 text-white rounded-md px-4 py-2">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className="max-w-md mx-auto">
                <div>
                  <h1 className="text-2xl font-semibold">RSVP List</h1>
                </div>
                <div className="divide-y divide-gray-200">
                  <ul className="py-8 text-base leading-6 space-y-4 sm:text-lg sm:leading-7">
                    {rsvps.map((rsvp, index) => (
                      <li key={index} className="border-b border-gray-200 pb-4">
                        <p><span className="font-semibold">Name:</span> {rsvp.name}</p>
                        <p><span className="font-semibold">Party Size:</span> {rsvp.partySize}</p>
                        <p><span className="font-semibold">Party Names:</span> {rsvp.partyNames.join(', ')}</p>
                        <p><span className="font-semibold">Attending:</span> {rsvp.attending.join(', ')}</p>
                        <button
                          onClick={() => handleDelete(rsvp.id)}
                          className="mt-2 bg-red-400 text-white rounded-md px-1 py-0"
                        >
                          Delete
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };


export default RSVPList;