// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import WeddingSite from './WeddingSite';
// import RSVPList from './RSVPList';

// function App() {
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     // Detect if the user is on a mobile device
//     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//     const mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());

//     setIsMobile(mobile);
//   }, []);

//   return (
//     <Router>
//       <Routes>
//         <Route exact path="/" element={<WeddingSite />} />
//         <Route path="/rsvps" element={<RSVPList />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;


//actual:



import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WeddingSite from './WeddingSite';
import RSVPList from './RSVPList';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());

    setIsMobile(mobile);
  }, []);

//   return (
//     <div>
//       {isMobile ? (
//         <Router>
//           <Routes>
//             <Route exact path="/" element={<WeddingSite />} />
//             <Route path="/rsvps" element={<RSVPList />} />
//           </Routes>
//         </Router>
//       ) : (
//         <div className="text-center mt-20">
//           <h1 className="text-3xl font-bold">Please visit this site using a mobile device.</h1>
//         </div>
//       )}
//     </div>
//   );
// }

return (
  <div>
    {/* Temporarily remove the isMobile condition */}
    <Router>
      <Routes>
        <Route exact path="/" element={<WeddingSite />} />
        <Route path="/rsvps" element={<RSVPList />} />
      </Routes>
    </Router>
    
    {/* You can keep this commented out for now */}
    {/* {!isMobile && (
      <div className="text-center mt-20">
        <h1 className="text-3xl font-bold">Please visit this site using a mobile device.</h1>
      </div>
    )} */}
  </div>
);
}

export default App;